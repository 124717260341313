import styled from 'styled-components/macro'

// toggle

const DropdownUpperRightWrapper = styled.div`
  position: absolute;
  top: .25rem;
  right: 1rem;
`
const DropdownToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100px;
  width: 2rem;
  height: 2rem;
  margin: 0 -.75rem 0 0;
  user-select: none;
  opacity: .6;
  cursor: pointer;
  
  transition: all .2s ease;

  &:hover {
    opacity: .8;
    background: var(--hover);
  }
  
  i {
    font-size: var(--l);
  }
`
const DropdownToggleWrapperTableCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: .33rem;
  user-select: none;
  opacity: .6;
  cursor: pointer;
  
  transition: all .2s ease;

  &:hover {
    opacity: .8;
    background: var(--hover);
  }
  
  i {
    font-size: var(--xl);
  }
`

// menu

const DropdownMenuWrapper = styled.div`
  margin: 0 0 2rem;
  padding: ${p => p.padded ? '1.5rem' : '0.5rem 1rem'};
  position: absolute;
  min-width: ${p => p.wide ? '360px' : '200px'};
  top:    ${p => p.top   ? 'auto' : p.lowered ? '3rem' : '0'};
  bottom: ${p => p.top   ? '2rem' : 'auto'};
  left:   ${p => p.right ? '0'    : 'auto'};
  right:  ${p => p.right ? 'auto' : '0'};
  background: white;
  box-shadow: 0px 10px 20px var(--shadow-overlay);
  /* border: 1px solid var(--hairline); */
  border-radius: 3px;
  z-index: 11000; // should be higher than nav tooltip to prevent timer from being covered
  max-height: 80vh;
  overflow-y: auto;

  hr {
    margin: .5rem 0;
  }

  // stick to bottom
  @media(max-width: 599px) {    
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    box-shadow: 0 0 0 1000px var(--shadow-overlay);
    border-radius: 0;
    border: none;
    margin: 0;
  }
`
const DropdownMenuAction = styled.div`
  position: relative;
  display: block;
  margin: 0 -.5rem;
  padding: .5rem .5rem .5rem 2.5rem;

  color: ${p => p.destructive ? 'var(--destructive)' : 'var(--brand-primary)'};
  border-radius: 3px;
  font-weight: 500;
  font-size: var(--s);

  cursor: ${p => p.disabled ? 'auto' : 'pointer'};
  user-select: none;

  transition: all .2s ease;

  &[disabled] {
    opacity: .5;
  }
  
  &:hover {
    background: ${p => p.disabled ? 'transparent' : 'var(--hover)'};
  }

  i {
    position: absolute;
    left: .5rem;
    top: .5rem;
    font-size: 22px;
  }
`

const DropdownMenuInlineText = styled.span`
  color: var(--text-tertiary);
  font-size: var(--xs);
  margin: 0 0 0 1rem;
`

const DropdownMenuHeader = styled.div`
  padding: .5rem 0 0;

  font-size: var(--s);
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 500;  
`
const DropdownMenuStaticRow = styled.div`
  margin: 0.5rem -1.5rem -.5rem;
  padding: .5rem 1.5rem;
  color: var(--text-primary); 
`

export {
  DropdownUpperRightWrapper,
  DropdownToggleWrapper,
  DropdownToggleWrapperTableCell,
  DropdownMenuWrapper,
  DropdownMenuAction,
  DropdownMenuInlineText,
  DropdownMenuHeader,
  DropdownMenuStaticRow,
}