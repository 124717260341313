import React, { useState, useContext } from 'react'
import Moment from 'moment'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import deleteBoard from 'actions/deleteBoard'
import updateBoard from 'actions/updateBoard'
import duplicateBoard from 'actions/duplicateBoard'
import ShareBoardModal from 'views/board/sharing/ShareBoardModal'
import MoveBoardModal from 'views/board/sharing/MoveBoardModal'
import Dropdown from 'views/_components/Dropdown'
import BubbleHeadList from 'views/_components/BubbleHeadList'
import { DebugContext } from 'views/DebugState'
import {
  DropdownMenuAction,
  DropdownToggleWrapperTableCell,
} from 'styles/dropdown'
import {
  Button,
} from 'styles/buttons'

const BoardListItem = ({
  loading,
  board,
  orgRef,
  boardMembers,
}) => {

  const debugging = useContext(DebugContext)

  const [ isSharingBoard, setIsSharingBoard ] = useState(false)
  const [ isMovingBoard, setIsMovingBoard ] = useState(false)

  if (loading) {
    return <BoardListItemWrapper key={board.id} to={`/b/${orgRef.id}/${board.id}`}>
      Loading...
    </BoardListItemWrapper>
  }

  return <BoardListItemWrapper key={board.id} to={`/b/${orgRef.id}/${board.id}`}>
    <div>
      <BoardTitle>{board.name || 'Unnamed board'}</BoardTitle>
      <BoardSubtitle>
        {Moment(board.createdOn.toDate()).calendar()}
      </BoardSubtitle>
    </div>

    <div>
      <BubbleHeadList users={boardMembers} />
    </div>

    <div>
      <Button
        small
        secondary="secondary"
        style={{marginRight: '1rem'}}
      >
        Open
      </Button>

      <Dropdown
        inlineBlock
        toggle={
          <DropdownToggleWrapperTableCell>
            <i className="material-icons-round">more_vert</i>
          </DropdownToggleWrapperTableCell>
        }
      >
        { ({close}) => {
          return <div>
            
            <DropdownMenuAction onClick={event => {
              event.preventDefault()
              close()
              setIsSharingBoard(true)
            }}>
              <i className="material-icons-round">supervisor_account</i>
              Export...
            </DropdownMenuAction>

            <DropdownMenuAction onClick={event => {
              event.preventDefault()
              close()
              const boardRef = orgRef.collection('boards').doc(board.id)
              LogEvent('duplicate-board')
              duplicateBoard({ orgRef, boardRef })
                .catch( error => LogError('duplicate this board', error) )
            }}>
              <i className="material-icons-round">content_copy</i>
              Duplicate board
            </DropdownMenuAction>
            
            { debugging &&
              <DropdownMenuAction onClick={event => {
                event.preventDefault()
                close()
                setIsMovingBoard(true)
              }}>
                <i className="material-icons-round">input</i>
                Move board
              </DropdownMenuAction>
            }
            
            <DropdownMenuAction onClick={event => {
              event.preventDefault()
              const name = window.prompt(`What do you want to call this board?`, board.name)
              if (name === null) {
                return
              }
              LogEvent('rename-board')
              const boardRef = orgRef.collection('boards').doc(board.id)
              updateBoard(boardRef, { name })
                .then(close)
                .catch( error => LogError('rename this board', error) )
            }}>
              <i className="material-icons-round">create</i>
              Rename board
            </DropdownMenuAction>

            <DropdownMenuAction destructive="destructive" onClick={event => {
              event.preventDefault()
              const reallyDelete = window.confirm(`Are you sure you want to delete this board? You can't undo this.`)
              if (reallyDelete) {
                LogEvent('delete-board')
                const boardRef = orgRef.collection('boards').doc(board.id)
                deleteBoard(boardRef)
                  .catch( error => LogError('delete this board', error) )
              }
            }}>
              <i className="material-icons-round">delete_outline</i>
              Delete board
            </DropdownMenuAction>
          </div>
        }}
      </Dropdown>
    </div>

    { isSharingBoard &&
      <ShareBoardModal
        board={board}
        boardOrgId={orgRef.id}
        isFacilitator={true}
        onClose={ () => setIsSharingBoard(false) }
      />
    }
    
    { isMovingBoard &&
      <MoveBoardModal
        board={board}
        orgRef={orgRef}
        onClose={ () => setIsMovingBoard(false) }
      />
    }
    
  </BoardListItemWrapper>
}

const BoardListItemWrapper = styled(Link)`
  padding: 1rem .5rem;
  cursor: pointer;
  position: relative;

  background: white;
  border-bottom: 1px solid var(--gray-90);
  
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  grid-column-gap: 1rem;
  align-items: center;

  cursor: pointer;

  &:hover {
    background: var(--hover-light);
  }

  @media(max-width: 599px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`
const BoardTitle = styled.div`
  font-family: var(--font-family-sans);
  font-family: var(--font-family-sans-display);
  color: var(--text-primary);
  font-weight: 600;
  font-size: var(--m);
  line-height: 1.1;
`
const BoardSubtitle = styled.div`
  color: var(--text-secondary);
  font-size: var(--s);
`

export default BoardListItem
