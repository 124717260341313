import React from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateBoard from 'actions/updateBoard'
import ToggleSwitch from 'views/_components/ToggleSwitch'
import { AppStateContext } from 'views/AppState'
import {
  DropdownMenuAction,
} from 'styles/dropdown'

const ROAMING_BETA_USERS = [
  'sam@directedworks.com',
  'brett@firstround.com',
  'tony@parkingreform.org',
  'ss8895@nyu.edu',
  'bonnie.roche@gmail.com',
  'tara@rupahealth.com',
  'mvcantre@med.umich.edu',
  'arthur.lender@instrument.com',
  'talk@michaelgregor.com',
]

const ParticipantRoaming = ({
  board,
  boardRef,
}) => {

  const participantsMayRoam = board.displayOptions && board.displayOptions.participantRoaming

  return <AppStateContext.Consumer>
    { ({auth}) => {

      if (!ROAMING_BETA_USERS.includes(auth.email)) {
        return
      }

      return <DropdownMenuAction onClick={() => {
        LogEvent('participants-may-roam')
        updateBoard(boardRef, {displayOptions: {participantRoaming: !participantsMayRoam}})
          .catch( error => LogError('allow participants to change slides', error) )
      }}>
        <i className="material-icons">dynamic_feed</i>
        People can view any slide
        <ToggleSwitch on={participantsMayRoam} />
      </DropdownMenuAction>
    }}
  </AppStateContext.Consumer>
}

export default ParticipantRoaming